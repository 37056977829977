<div class="headline">
    <strong>The Seeding & Barter Influencer Platform</strong>
</div>
<div class="signup-btn">
    <a href="https://wa.me/971524965822" target="_blank">Start your campaign</a>
</div>
<p>
    Promote your brand and generate word of mouth with top UAE influencers 
</p>
<div class="how-it-works">
    <h3>How it works</h3>
    <iframe width="656" height="369" src="https://www.youtube.com/embed/kMT5G8vtPfI?rel=0&fs=0&showinfo=0"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
</div>
<div class="steps">
    <div class="step">
        <div><strong>Step 1</strong></div>
        <img src="assets/pen.svg" />
        <p>
            Choose influences to partner with 
        </p>
    </div>
    <div class="step">
        <div><strong>Step 2</strong></div>
        <img src="assets/posts.svg" />
        <p>
            Influencers create content and showcase your brand to their followers 
        </p>
    </div>
    <div class="step">
        <div><strong>Step 3</strong></div>
        <img src="assets/chart.svg" />
        <p>
            Monitor campaign results and share their posts on your social media
        </p>
    </div>
</div>

<div class="our-clients">
    <h3>Trusted Clients</h3>
    <div class="clients">
        <div class="logo">
            <img src="assets/clients/mcdonalds.png">
        </div>
        <div class="logo">
            <img src="assets/clients/vox.png">
        </div>
        <div class="logo">
            <img src="assets/clients/dunkin.png">
        </div>
        <div class="logo">
            <img src="assets/clients/careem.png">
        </div>
        <div class="logo">
            <img src="assets/clients/cafu.png">
        </div>
        <div class="logo">
            <img src="assets/clients/applebees.png">
        </div>
        <div class="logo">
            <img src="assets/clients/bioderma.png">
        </div>
        <div class="logo">
            <img src="assets/clients/swissbutter.png">
        </div>
        <div class="logo">
            <img src="assets/clients/esteelauder.png">
        </div>
        <div class="logo">
            <img src="assets/clients/noon.png">
        </div>
        <div class="logo">
            <img src="assets/clients/timhortons.png">
        </div>
        <div class="logo">
            <img src="assets/clients/warnerbros.png">
        </div>
        <div class="logo">
            <img src="assets/clients/qidz.png">
        </div>
        <div class="logo">
            <img src="assets/clients/mac.png">
        </div>
        <div class="logo">
            <img src="assets/clients/skidubai.png">
        </div>
    </div>
</div>
<div class="testimonials">
    <h3>Client Testimonials</h3>
    <!-- <div class="testimonials-navigator">
        <a href="#testimonial-1">0</a>
        <a href="#testimonial-2">0</a>
        <a href="#testimonial-3">0</a>
        <a href="#testimonial-4">0</a>
    </div> -->
    <div class="testimonials-row-wrapper">
        <div class="testimonials-row">
            <div class="testimonial" id="testimonial-1">
                <div class="quote-icon">
                    <img src="assets/quote.png" />
                </div>
                <div class="quote">
                    The platform is great in terms of metrics, influencers and rates. It's easy to use and has a a great
                    pool of influencers
                </div>
                <div class="quote-author-container">
                    <div class="quote-author-name">
                        Shahd Ismail
                    </div>
                    <div class="quote-author-position">
                        Senior Marketing Executive
                    </div>
                    <div class="quote-author-brand">
                        Dunkin
                    </div>
                </div>
            </div>
            <div class="testimonial" id="testimonial-2">
                <div class="quote-icon">
                    <img src="assets/quote.png" />
                </div>
                <div class="quote">
                    The platform is great and is an amazing idea to ease the influencer gifting process. it’s been a
                    good
                    experience and we really like the last influencer we worked with. We are really happy so far and are
                    planning on more gifting options for the future.
                </div>
                <div class="quote-author-container">
                    <div class="quote-author-name">
                        Nadine Qattan
                    </div>
                    <div class="quote-author-position">
                        Design Manager
                    </div>
                    <div class="quote-author-brand">
                        Maison Des Fleurs
                    </div>
                </div>
            </div>
            <div class="testimonial" id="testimonial-3">
                <div class="quote-icon">
                    <img src="assets/quote.png" />
                </div>
                <div class="quote">
                    Buzzbee has been amazing, and we use it to reach out to some influencers to promote our brand.
                    Everything is quite good, and it has helped us a lot with getting in touch with social media
                    influencers.
                    More power to your platform and kudos to the whole team.
                </div>
                <div class="quote-author-container">
                    <div class="quote-author-name">
                        Shiv dhasmana
                    </div>
                    <div class="quote-author-position">
                        Manager
                    </div>
                    <div class="quote-author-brand">
                        Gallus Rotisserie
                    </div>
                </div>
            </div>
            <div class="testimonial" id="testimonial-4">
                <div class="quote-icon">
                    <img src="assets/quote.png" />
                </div>
                <div class="quote">
                    BuzzBee has been a pleasant surprise. The idea of such a platform is great, convenient and quite
                    flexible
                    for any campaign support required. The access to such a wide range of influencers is great and the
                    way
                    you engage with them via the portal is extremely convenient. Recommended for any campaign as it is
                    great value for the incentives provided.
                </div>
                <div class="quote-author-container">
                    <div class="quote-author-name">
                        Omar Bitar
                    </div>
                    <div class="quote-author-position">
                        Senior Marketing Manager
                    </div>
                    <div class="quote-author-brand">
                        Hala Taxi
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="subscription-packages-container">
    <app-subscription-packages></app-subscription-packages>
</div>